import React from "react";
import styled from "@emotion/styled";
import color, {hexToRGB} from "../../styles/colors";
import typography from "../../styles/typography";

const IframeContent = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
    }
`;

const IframeContainer = styled.div`
    height: 0;
    overflow: hidden;
    padding-top: ${({ratio}) => `${ratio * 100}%`};
    position: relative;
`

const Wrapper = styled.div`
    margin-bottom: 48px;

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
    }
    
    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
    }
    
    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
    }
`

const IframeCaption = styled.figcaption`
    ${typography.meta};
    margin-top: 8px;
    text-align: left;
    color: ${hexToRGB(color("dark"), 0.6)};

    @media screen and (min-width: 1800px) {
        margin-top: 16px;
    }
`;

const IframeHtml = ({content, ratio, caption}) => {
    return (
        <Wrapper>
            <IframeContainer ratio={ratio ? parseFloat(ratio) : 0.5625}>
                <IframeContent dangerouslySetInnerHTML={{__html: content}} />
            </IframeContainer>
            {caption ?
                <IframeCaption dangerouslySetInnerHTML={{__html: caption}} />
            : null}
        </Wrapper>

    );
}

export default IframeHtml;