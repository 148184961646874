import React from "react";
import styled from "@emotion/styled";
import color, {hexToRGB} from "../../styles/colors";
import typography from "../../styles/typography";
import CustomLink from "../Elements/CustomLink";
import FadeUp from "../Effects/FadeUp";

const Article = styled(FadeUp)`
    margin-bottom: 21px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: ${color("dark")};
    text-align: left;
    transition-delay: 0s;

    &:last-of-type{
        margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
        padding-left: 7.5px;
        padding-right: 7.5px;
        width: 50%;

        &:first-of-type{
            width: 100%;
        }

        &:nth-of-type(n+2){
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 1024px) {
        width: 33.3333%;
        margin-bottom: 0;
        transition-delay: ${props => `${props.delay}`};

        &:first-of-type{
            width: 33.3333%;
        }
    }

    @media screen and (min-width: 1200px) {
        padding-left: 11.5px;
        padding-right: 11.5px;
    }

    @media screen and (min-width: 1800px) {
        padding-left: 14px;
        padding-right: 14px;
    }
`;
const PublishDate = styled.p`
    ${typography.label};
    text-transform: uppercase;
    margin-bottom: 0;
    position: relative;

    @media screen and (min-width: 1200px) {
        margin-bottom: 0;
    }
`;
const Author = styled.p`
    ${typography.label};
    text-transform: uppercase;
    margin-bottom: 24px;
    position: relative;
    font-weight: 600;

    @media screen and (min-width: 1800px) {
        margin-bottom: 34px;
    }
`;
const ArticleTitle = styled.h3`
    ${typography.p1};
    font-weight: bold;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
        margin-bottom: 16px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 24px;
    }
`;
const ArticleDescription = styled.div`
    ${typography.standard};
    margin-bottom: 40px;
    flex-grow: 1;
`;
const ArticleLink = styled.span`
    ${typography.link};
    display: inline-block;
    text-decoration: underline;
`;
const ArticleDetails = styled(CustomLink)`
    text-decoration: none;
    color: ${color("dark")};
    transition: color 0.2s ease, background 0.2s ease;
    display: inline-block;
    padding: 23px 28px 28px;
    background-color: ${color("white")};
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid ${color("grey")};
    hr{
        border-color: ${hexToRGB(color("grey"),0.3)};
    }

    &:hover{
        background: ${color("dark blue")};
        color: ${color("white")};

        hr{
            border-top: 1px solid ${color("white")};
        }
    }

    @media screen and (min-width: 768px) {
        padding: 24px 24px 48px;
    }
`;

const Line = styled.hr`
    text-decoration: none;
    color: inherit;
    width: 34px;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-top-width: 1px;
    margin: 0 0 21px;

    @media screen and (min-width: 1200px) {
        margin-bottom: 29px;
    }
`;

const ArticleComponent = ({date, author, title, description, url, linkText, internal, index}) => {

    return (
        <Article delay={`${index * 0.2}s`}>
            <ArticleDetails url={url} internal={internal}>
                <PublishDate>{date}</PublishDate>
                <Author>{author}</Author>
                <Line />
                <ArticleTitle>
                    {title}
                </ArticleTitle>
                <ArticleDescription dangerouslySetInnerHTML={{ __html: description }} />
                <ArticleLink>
                    {linkText}
                </ArticleLink>
            </ArticleDetails>
        </Article>
    );
};

export default ArticleComponent;
