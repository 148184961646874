import React from "react";
import styled from "@emotion/styled";
import GatsbyImage from "gatsby-image";
import color, {hexToRGB} from "../../styles/colors";
import typography from "../../styles/typography";

const Image = styled(GatsbyImage)`
    width: 100%;
`;

const Inner = styled.div`
    display: inline-block;
    text-align: left;
    width: ${props => `${props.imageWidth}px`};
    max-width: 100%;
`;

const ImageCaption = styled.figcaption`
    ${typography.meta};
    margin-top: 8px;
    text-align: left;
    color: ${hexToRGB(color("dark"), 0.6)};

    @media screen and (min-width: 1800px) {
        margin-top: 16px;
    }
`;

const ImageContainer = styled.figure`
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;

    @media screen and (min-width: 768px) {
        margin-top: 48px;
        margin-bottom: 48px;
    }

    @media screen and (min-width: 1200px) {
        width: auto;
        max-width: 839px;
        text-align: center;
        margin: 64px auto;
    }

    @media screen and (min-width: 1800px) {
        width: auto;
        max-width: 1217px;
        margin-top: 72px;
        margin-bottom: 72px;
    }
`

const ContentImage = ({fluid, alt, caption, width}) => {
    return (
        <ImageContainer>
            <Inner imageWidth={width}>
                <Image fluid={{...fluid, sizes: "(min-width: 1800px) 1217px, 100vw"}} alt={alt} />
                {caption ?
                    <ImageCaption dangerouslySetInnerHTML={{__html: caption}} />
                : null}
            </Inner>
        </ImageContainer>

    );
}

export default ContentImage;