import React from "react";
import styled from "@emotion/styled";

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const IframeContainer = styled.div`
    height: 0;
    overflow: hidden;
    padding-top: ${({ratio}) => `${ratio * 100}%`};
    position: relative;
    margin-bottom: 48px;

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
    }
    
    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
    }
    
    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
    }
`;

const IframeEmbed = ({url, ratio}) => {
    return (
        <IframeContainer ratio={ratio ? parseFloat(ratio) : 0.5625}>
            <Iframe src={url} frameborder='0' />
        </IframeContainer>
    );
}

export default IframeEmbed;