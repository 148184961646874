import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import color from "../../styles/colors";
import Container from "../Context/Container";
import Section from "../Context/Section";
import HoverDropdown from "../Elements/HoverDropdown";
import { graphql, useStaticQuery } from "gatsby";

const HeroWrapper = styled.div`
`;

const TitleWrapper = styled.div`
    margin-bottom: 34px;
    ${props => props.isArticle ? css`
        display: flex;
        flex-direction: column-reverse;
    ` : null}
    

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        ${props => props.isArticle ? css`
            display: block;
        ` : null}
    }
`;

const Title = styled.h1`
    ${typography.h2Hero};
    margin-bottom: 12px;

    @media screen and (min-width: 768px) {
        margin-bottom: 2px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 10px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 8px;
    }
`;

const Subtitle = styled.p`
    ${typography.p1};
`;

const PublishDate = styled.p`
    ${typography.label};
    text-transform: uppercase;
    margin-bottom: 0;
`;

const Author = styled.p`
    ${typography.label};
    text-transform: uppercase;
    position: relative;
    font-weight: 600;
`;

const Line = styled.hr`
    text-decoration: none;
    color: inherit;
    width: 32px;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-top-width: 1px;
    display: none;

    @media screen and (min-width: 768px) {
        display: inline-block;
        margin: 0 8px;
    }

    @media screen and (min-width: 1200px) {
        width: 60px;
        margin: 0 16px;
    }
`;

const MetaWrapper = styled.div`
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 56px;
        justify-content: space-between;
    }
`;

const Meta = styled.div`
    margin-bottom: 16px;
    display: ${props => props.showMobile ? "block" : "none"};

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        display: ${props => props.showDesktop ? "flex" : "none"};
        flex-direction: row;
        align-items: center;
    }
`;

const ExploreLink = styled.a`
    ${typography.link};
    color: ${color("dark")};
    transition: color 0.2s ease;
    white-space: nowrap;

    &:hover{
        color: ${color("dark blue")};
    }
`;

const SocialLinks = styled.div`
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

const HeroArticle = ({title, tagline, date, author, exploreUrl, exploreText, pageUrl, theme}) => {
    const { site: {siteMetadata: {siteUrl}} } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                    }
                }
            }
        `
    )

    const currentUrl = encodeURIComponent(siteUrl + pageUrl);
    return (
        <Section paddingS="225px 0 72px" paddingM="300px 0 72px" paddingL="370px 0 104px" paddingXL="517px 0 128px" paddingXXL="537px 0 148px" bg="white" fg="dark" theme={theme} flexGrow="1">
            <Container>
                <HeroWrapper>
                    <TitleWrapper isArticle={date && author}>
                        <Title>
                            {title}
                        </Title>
                        {tagline ? (
                            <Subtitle>
                                {tagline}
                            </Subtitle>
                        ) : null}
                        {date && author ? (
                            <Meta showMobile={true} showDesktop={false}>
                                <PublishDate>{date}</PublishDate>
                                <Line />
                                <Author>{author}</Author>
                            </Meta>
                        ) : null}
                    </TitleWrapper>
                    <MetaWrapper>
                        {date && author ? (
                            <Meta showMobile={false} showDesktop={true}>
                                <PublishDate>{date}</PublishDate>
                                <Line />
                                <Author>{author}</Author>
                            </Meta>
                        ) : null}
                        {exploreUrl ? (
                            <ExploreLink href={exploreUrl} target="_blank" rel="noopener noreferrer">
                                {exploreText}
                            </ExploreLink>
                        ) : (
                            <SocialLinks>
                                <HoverDropdown
                                    title="Share this article"
                                    links={[
                                        {
                                            name: "Email",
                                            url: `mailto:?subject=${encodeURIComponent(title)}&body=${currentUrl}`,
                                            key: "email",
                                            email: true
                                        },
                                        {
                                            name: "Facebook",
                                            url: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
                                            key: "facebook",
                                            newWindow: true
                                        },
                                        {
                                            name: "Twitter",
                                            url: `http://twitter.com/share?text=${encodeURIComponent(title)}&url=${currentUrl}`,
                                            key: "twitter",
                                            newWindow: true
                                        },
                                        {
                                            name: "LinkedIn",
                                            url: `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`,
                                            key: "linkedin",
                                            newWindow: true
                                        }
                                    ]}
                                    width="90%"
                                />
                            </SocialLinks>
                        )}
                    </MetaWrapper>
                </HeroWrapper>
            </Container>
        </Section>
    );
};

export default HeroArticle;
