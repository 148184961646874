import React from "react";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import Section from "../Context/Section";
import ParallaxImage from "../Effects/ParallaxImage";
import color, { hexToRGB } from "../../styles/colors";

const Wrapper = styled.div`
    width: 100%;

    img{
        width: 100%;
    }
`;

const ImageWrapper = styled.div`
    width: 100%;

    img{
        width: 100%;
    }
`;

const Caption = styled.div`
    ${typography.meta};
    padding: 0 20px;

    @media screen and (min-width: 600px) {
        padding: 0 48px;
    }

    @media screen and (min-width: 768px) {
        padding: 0 20px;
    }

    p{
        margin-top: 8px;
        color: ${hexToRGB(color("dark"), 0.6)};
        
        /* @media screen and (min-width: 768px) {
            margin-left: 57px;
            margin-right: 57px;
        }

        @media screen and (min-width: 1200px) {
            width: 719px;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (min-width: 1800px) {
            width: 928px;
        } */
    }

    a{
        word-wrap: break-word;
        color: ${hexToRGB(color("dark"), 0.6)};
        transition: color 0.2s ease;

        &:hover{
            color: ${hexToRGB(color("dark"), 1)};
        }
    }
`;

const FeatureImage = ({image, credits, theme}) => {
    return (
        <Section paddingS="0" paddingM="0" paddingL="0" paddingXL="0" paddingXXL="0" bg="white" fg="dark" theme={theme}>
            <Wrapper>
                <ImageWrapper>
                    <ParallaxImage>
                        {image}
                    </ParallaxImage>
                </ImageWrapper>
                {credits && <Caption>
                    <p>{credits}</p>
                </Caption>}
            </Wrapper>
        </Section>
    );
};

export default FeatureImage;
