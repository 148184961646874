import React, {useState, useEffect, useRef} from "react";

import styled from "@emotion/styled";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: ${props => `${props.imageRatio * 1.04}%`};
    overflow: hidden;

    @media screen and (min-width: 768px) {
        padding-top: ${props => `${props.imageRatio * 0.84}%`};
    }
`;

const Inner = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 140%;
    transform: translate(-50%, -50%) translate3d(0,${props => -1 * props.offset}px,0);

    @media screen and (min-width: 768px) {
        width: 100%;
    }
`;

const FadeUp = ({children, className}) => {
    const [offset, setOffset] = useState(0);
    const [ratio, setRatio] = useState(0);
    const wrapper = useRef();
    const inner = useRef();

    useEffect(() => {
        adjustPosition();
        const imageRatio = 100 * inner.current.clientHeight/inner.current.clientWidth;
        setRatio(imageRatio);

        if(typeof window !== `undefined`){
            window.addEventListener("scroll", adjustPosition);
            window.addEventListener("resize", adjustPosition);
        };

        return () => {
            window.removeEventListener("scroll", adjustPosition);
            window.removeEventListener("resize", adjustPosition);
        };
    },[]);

    const adjustPosition = () => {
        const {innerHeight: wh} = window;
        const speed = 100;
        const elementBounds = wrapper.current.getBoundingClientRect();
        const distToElement = elementBounds.top + elementBounds.height * 0.5 - wh*0.5;
        const distPercentage = distToElement * speed / wh;
        const newOffset = distPercentage;
        setOffset(newOffset);
    }

    return (
        <Wrapper className={className} imageRatio={ratio} ref={wrapper}>
            <Inner offset={offset} ref={inner}>
                {children}
            </Inner>
        </Wrapper>
    );
};

export default FadeUp;
