import React from "react";

import GatsbyImage from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import HeroArticle from "../components/General/HeroArticle";
import FeatureImage from "../components/General/FeatureImage";
import FeaturedBlock from "../components/News/FeaturedBlock";
import Body from "../components/General/Body";
import Footer from "../components/Context/Footer";

import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";

const NewsArticle = ({ data: staticData }) => {
    const data = usePreviewData(staticData);
    const {
        name,
        author,
        date,
        content,
        feature_image,
        meta_description,
        share_image,
        body,
        credits
    } = data.prismicNewsArticle.data;
    const articles = data.allPrismicNewsArticle ? data.allPrismicNewsArticle.nodes.map(article => {
        return {
            key: article.id,
            url: article.external_platform ? article.external_article_link.url : article.url,
            description: article.data.summary.html,
            date: article.data.date,
            author: article.data.author,
            title: article.data.name.text,
            type: article.data.external_platform || null,
            buttonText: `Read this article${article.data.external_platform ? ` on ${article.data.external_platform}` : ""}`
        }
    }) : [];
    const theme = "dark";
    return (
        <Layout>
            <SEO title={name.text} description={meta_description} image={share_image.url} />
            <Header theme={theme} />
            <HeroArticle theme={theme} title={name.text} date={date} author={author} pageUrl={data.prismicNewsArticle.url} />
            <FeatureImage theme={theme} image={feature_image.fluid ? <GatsbyImage fluid={feature_image.fluid} alt={feature_image.alt} loading={"eager"} /> : feature_image.fluid}  credits={feature_image.copyright} />
            <Body
                theme={theme}
                type="article"
                content={content.html}
                title={name.text}
                pageUrl={data.prismicNewsArticle.url}
                slices={body}
                credits={credits.html}
            />
            <FeaturedBlock
                theme={theme}
                title="More articles"
                featured={articles}
            />
            <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    query NewsArticle($slug: String!) {
        prismicNewsArticle(uid: {eq: $slug}) {
            uid
            url
            data {
                author
                date(formatString: "MMM D YYYY")
                summary {
                    html
                }
                external_platform
                external_article_link {
                    url
                    target
                    link_type
                }
                name {
                    text
                }
                content {
                    html
                }
                body {
                    __typename
                    ... on PrismicNewsArticleBodyLead {
                        id
                        primary {
                            content {
                                html
                            }
                        }
                    }
                    ... on PrismicNewsArticleBodyText {
                        id
                        primary {
                            text {
                                html
                            }
                        }
                    }
                    ... on PrismicNewsArticleBodyIframeEmbed {
                        id
                        primary {
                            ratio
                            url
                        }
                    }
                    ... on PrismicNewsArticleBodyImage {
                        id
                        primary {
                            caption1 {
                                html
                            }
                            image {
                                alt
                                dimensions {
                                    width
                                    height
                                }
                                fluid(maxWidth: 1800) {
                                    ...GatsbyPrismicImageFluid
                                }
                            }
                        }
                    }
                    ... on PrismicNewsArticleBodyVideo {
                        id
                        primary {
                            video {
                                html
                                width
                                height
                            }
                            caption {
                                html
                            }
                        }
                    }
                    ... on PrismicNewsArticleBodyQuote {
                        id
                        primary {
                            quote {
                                html
                            }
                        }
                    }
                }
                feature_image {
                    alt
                    copyright
                    fluid(maxWidth: 1800) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                meta_description
                share_image {
                    url
                }
                credits {
                    html
                }
            }
        }
        allPrismicNewsArticle(filter: {uid: {ne: $slug}}, limit: 3, sort: {fields: data___date, order: DESC}) {
            nodes {
                url
                data {
                    author
                    date(formatString: "MMM D YYYY")
                    name {
                        text
                    }
                    summary {
                        html
                    }
                    external_article_link {
                        url
                    }
                    external_platform
                }
            }
        }
    }
`



export default NewsArticle;
