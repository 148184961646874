import React from "react";
import styled from "@emotion/styled";
import color, {hexToRGB} from "../../styles/colors";
import typography from "../../styles/typography";
import Container from "../Context/Container";
import Section from "../Context/Section";
import HoverDropdown from "../Elements/HoverDropdown";
import { graphql, useStaticQuery } from "gatsby";
import IframeEmbed from "./IframeEmbed";
import IframeHtml from "./IframeHtml";
import ContentImage from "./ContentImage";

const Standard = styled.div`
    ${typography.standard};
    color: ${color("dark")};
    margin-bottom: 48px;

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
    }

    h1{
        ${typography.h2};
        margin-top: 64px;

        @media screen and (min-width: 768px) {
            margin-bottom: 24px;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 21px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 16px;
        }
    }

    h2{
        ${typography.h3};
        margin-bottom: 24px;
        font-weight: bold;
        margin-top: 64px;

        @media screen and (min-width: 768px) {
            margin-bottom: 24px;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 21px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 16px;
        }
    }

    h3{
        ${typography.h4};
        margin-bottom: 26px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 64px;
        font-size: 18px;

        @media screen and (min-width: 768px) {
            margin-bottom: 24px;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 21px;
            font-size: 20px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 16px;
        }
    }

    h4, h5, h6{
        ${typography.label};
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 64px;

        @media screen and (min-width: 768px) {
            margin-bottom: 24px;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 21px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 16px;
        }
    }

    p, ul, ol, h1, h2, h3, h4, h5, h6{
        @media screen and (min-width: 768px) {
            margin-left: 57px;
            margin-right: 57px;
        }

        @media screen and (min-width: 1200px) {
            width: 719px;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (min-width: 1800px) {
            width: 928px;
        }
    }

    .lead, .quote{
        ${typography.p1};
        display: block;
        margin-top: 48px !important;
        margin-bottom: 48px;

        @media screen and (min-width: 768px) {
            width: 81%;
            margin-top: 56px !important;
            margin-bottom: 56px;
        }

        @media screen and (min-width: 1200px) {
            margin-top: 64px !important;
            margin-bottom: 64px;
        }

        @media screen and (min-width: 1800px) {
            margin-top: 72px !important;
            margin-bottom: 72px;
        }
    }

    .lead{
        font-weight: 600;
    }

    p:first-child{
        .lead, .quote {
            margin-top: 0 !important;
        }
    }

    p:last-child{
        .lead, .quote {
            margin-bottom: 0;
        }
    }

    figure, .embed-container{
        margin-top: 25px;
        margin-bottom: 25px;
        text-align: center;

        .inner {
            display: inline-block;
            text-align: left;
        }

        figcaption{
            ${typography.meta};
            margin-top: 8px;
            text-align: left;
            color: ${hexToRGB(color("dark"), 0.6)};
        }

        @media screen and (min-width: 768px) {
            margin-top: 48px;
            margin-bottom: 48px;
        }

        @media screen and (min-width: 1200px) {
            width: auto;
            max-width: 839px;
            text-align: center;
            margin: 64px auto;
        }

        @media screen and (min-width: 1800px) {
            width: auto;
            max-width: 1217px;
            margin-top: 72px;
            margin-bottom: 72px;

            figcaption{
                margin-top: 16px;
            }
        }
    }

    h1 + h2, h1 + h3, h1 + h4, h1 + h5, h1 + h6, h2 + h3, h2 + h4, h2 + h5, h2 + h6, h3 + h4, h3 + h5, h3 + h6, h4 + h5, h4 + h6, h5 + h6{
        margin-top: 0;
    }

    a{
        word-wrap: break-word;
    }

    &:first-child {
        p:first-child {
            .lead {
                margin-bottom: 16px;

                @media screen and (min-width: 768px) {
                    margin-bottom: 32px;
                }

                @media screen and (min-width: 1200px) {
                    margin-bottom: 37px;
                }

                @media screen and (min-width: 1800px) {
                    margin-bottom: 41px;
                }
            }
        }
    }
`;

const Lead = styled.div`
    ${typography.standard};
    color: ${color("dark")};
    margin-bottom: 48px;

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
        margin-left: 57px;
        margin-right: 57px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
        width: 719px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
        width: 928px;
    }

    &:first-child {
        margin-bottom: 16px;

        @media screen and (min-width: 768px) {
            margin-bottom: 32px;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 37px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 41px;
        }
    }

    p{
        ${typography.p1};
        display: block;
        font-weight: 600;
        margin-top: 24px;
        margin-bottom: 24px;

        &:last-child{
            margin-bottom: 24px;
        }

        @media screen and (min-width: 768px) {
            width: 81%;
        }

        @media screen and (min-width: 1200px) {
            margin-top: 32px;
            margin-bottom: 32px;

            &:last-child{
                margin-bottom: 32px;
            }
        }
    }

    a{
        word-wrap: break-word;
    }
`;

const Quote = styled.div`
    ${typography.standard};
    color: ${color("dark")};
    margin-bottom: 48px;

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
        margin-left: 57px;
        margin-right: 57px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
        width: 719px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
        width: 928px;
    }

    p {
        ${typography.p1};
        display: block;
        margin-top: 32px;
        margin-bottom: 32px;

        &:last-child{
            margin-bottom: 32px;
        }

        @media screen and (min-width: 768px) {
            width: 81%;
        }

        @media screen and (min-width: 1200px) {
            margin-top: 42px;
            margin-bottom: 42px;

            &:last-child{
                margin-bottom: 42px;
            }
        }

        @media screen and (min-width: 1800px) {
            margin-top: 56px;
            margin-bottom: 56px;

            &:last-child{
                margin-bottom: 56px;
            }
        }
    }

    a{
        word-wrap: break-word;
    }
`;

const MediaWrapper = styled.div`
    @media screen and (min-width: 1200px) {
        width: auto;
        max-width: 839px;
        text-align: center;
        margin: 0 auto;
    }

    @media screen and (min-width: 1800px) {
        width: auto;
        max-width: 1217px;
    }
`;

const SocialLinks = styled.div`
    width: auto;

    @media screen and (min-width: 768px) {
        margin-left: 57px;
        margin-right: 57px;
    }

    @media screen and (min-width: 1200px) {
        width: 719px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (min-width: 1800px) {
        width: 928px;
    }
`;

const Credits = styled.div`
    ${typography.label};
    color: ${color("dark")};
    margin-bottom: 48px;
    text-transform: none;

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
    }

    p{
        @media screen and (min-width: 768px) {
            margin-left: 57px;
            margin-right: 57px;
        }

        @media screen and (min-width: 1200px) {
            width: 719px;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (min-width: 1800px) {
            width: 928px;
        }
    }

    a{
        color: inherit;
        transition: color 0.2s ease;

        &:hover{
            color: ${color("dark blue")};
        }
    }
`;

const Body = ({content, type, title, pageUrl, theme, slices = [], credits}) => {
    const { site: {siteMetadata: {siteUrl}} } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                    }
                }
            }
        `
    );

    const currentUrl = encodeURIComponent(siteUrl + pageUrl);
    return (
        <Section bg="white" fg="dark" hr="grey" theme={theme}>
            <Container>
                {content ? <Standard dangerouslySetInnerHTML={{ __html: content }}/> : null}
                {slices && slices.length ? slices.map(slice => {

                    switch (slice.__typename) {
                        case "PrismicNewsArticleBodyText":
                            return <Standard key={slice.id} dangerouslySetInnerHTML={{ __html: slice.primary.text.html }}/>
                        case "PrismicNewsArticleBodyIframeEmbed":
                            return (
                                <MediaWrapper>
                                    <IframeEmbed key={slice.id} url={slice.primary.url} ratio={slice.primary.ratio} />
                                </MediaWrapper>
                            );
                        case "PrismicNewsArticleBodyLead":
                            return <Lead key={slice.id} dangerouslySetInnerHTML={{ __html: slice.primary.content.html }}/>
                        case "PrismicNewsArticleBodyImage":
                            return <ContentImage
                                key={slice.id}
                                fluid={slice.primary.image.fluid}
                                alt={slice.primary.image.alt}
                                caption={slice.primary.caption1.html}
                                width={slice.primary.image.dimensions.width}
                            />
                        case "PrismicNewsArticleBodyVideo":
                            const videoRatio = parseInt(slice.primary.video.height)/parseInt(slice.primary.video.width);
                            return (
                                <MediaWrapper>
                                    <IframeHtml key={slice.id} content={slice.primary.video.html} ratio={isNaN(videoRatio) ? null : videoRatio} caption={slice.primary.caption.html} />
                                </MediaWrapper>
                            );
                        case "PrismicNewsArticleBodyQuote":
                            return <Quote key={slice.id} dangerouslySetInnerHTML={{ __html: slice.primary.quote.html }}/>
                    }
                }) : null}
                {credits ? (
                    <Credits dangerouslySetInnerHTML={{__html: credits}} />
                ): null}
                <SocialLinks>
                    <HoverDropdown
                        title={type ? `Share this ${type}` : null}
                        links={[
                            {
                                name: "Email",
                                url: `mailto:?subject=${encodeURIComponent(title)}&body=${currentUrl}`,
                                key: "email",
                                email: true
                            },
                            {
                                name: "Facebook",
                                url: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
                                key: "facebook",
                                newWindow: true
                            },
                            {
                                name: "Twitter",
                                url: `http://twitter.com/share?text=${encodeURIComponent(title)}&url=${currentUrl}`,
                                key: "twitter",
                                newWindow: true
                            },
                            {
                                name: "LinkedIn",
                                url: `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`,
                                key: "linkedin",
                                newWindow: true
                            }
                        ]}
                        width="90%"
                        horizontal={true}
                    />
                </SocialLinks>
            </Container>
        </Section>
    );
};

export default Body;
