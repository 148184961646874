import React, {useState, Fragment} from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";

const ShareWrapper = styled.div`
    white-space: nowrap;
    position: relative;
    z-index: 50;

    p{
        ${typography.label};
        font-weight: 600;
        color: ${color("dark")};
        transition: color 0.2s ease;
        display: ${props => props.hideTitleMobile ? "none" : "block"}
    }

    
    @media screen and (min-width: ${props => props.breakTablet ? "1200px" : "768px"}) {
        ${props => !props.horizontal ? css`
            display: inline-flex;
            flex-direction: column-reverse;
        ` : null}

        p{
            ${props => props.small ? typography.menuLink : typography.link};
            text-decoration: underline;
            text-transform: none;
            display: block;
            ${props => !props.horizontal ? css`
                cursor: hand;
                cursor: pointer;
            ` : css`
                margin-bottom: 4px;
            `}
        }
    }
`;

const ShareLinks = styled.div`
    ${typography.link};
    ${props => props.small ? typography.menuLink : typography.link};
    transition: color 0.2s ease;
    white-space: nowrap;
    cursor: hand;
    cursor: pointer;
    text-decoration: none;

    ul{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: ${props => props.numLinks >= 3 ? "100%" : `${100 * props.numLinks / 3 - 10}%`};

        li{
            a{
                color: ${color("dark")};
                text-decoration: underline;
                padding: 6px 0;
                display: inline-block;
                transition: color 0.2s ease;
                font-weight: normal;
                background: ${color("white")};

                &:hover{
                    color: ${color("dark blue")};
                }
            }

            &:last-of-type{
                display: none;
            }
        }
    }

    &:hover{
        color: ${color("dark blue")};
    }

    ${props => !props.horizontal ? css`
        @media screen and (min-width: ${props.breakTablet ? "1200px" : "768px"}) {
            display: ${props.open ? "block" : "none"};
            padding-bottom: ${props.reverse ? "0" : props.small ? "18px" : "24px"};
            position: absolute;
            ${props.reverse ? "top: 100%;" : "bottom: 100%;"};
            min-width: calc(100% + 40px);
            margin: 0 -12px;

            ul{
                display: inline-block;
                width: ${props.width ? props.width : "auto"};

                li{
                    a{
                        width: 100%;
                        border-bottom: 1px solid ${color("grey")};
                        text-decoration: none;
                        padding: 12px 12px;
                    }
                }
            }
        }
    ` : css`
        @media screen and (min-width: ${props.breakTablet ? "1200px" : "768px"}) {
            ul{
                justify-content: flex-start;

                li{
                    a{
                        padding: 6px 24px;
                        text-decoration: none;
                    }

                    &:first-of-type{
                        a{
                            padding-left: 0;
                        }
                    }

                    &:last-of-type{
                        a{
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    `}
`;

const Line = styled.li`
    padding: 6px 0;

    span{
        display: inline-block;
        height: 100%;
        width: 1px;
        background: ${color("grey")};
    }

    ${props => !props.horizontal ? css`
        @media screen and (min-width: ${props.breakTablet ? "1200px" : "768px"}) {
            display: none;
        }
    ` : null}
`;

const HoverDropdown = ({title, links, reverse, hideTitleMobile, breakTablet, width, horizontal, small, className}) => {
    const [shareOpen, setShareOpen] = useState(false);

    const openShareLinks = () => {
        setShareOpen(true);
    }

    const closeShareLinks = () => {
        setShareOpen(false);
    }

    const openLink = (e) => {
        const {href, dataset} = e.target;
        const {target} = dataset;

        if(typeof window !== `undefined` && target === "window" && window.innerWidth >= 768){
            e.preventDefault();

            const {innerWidth} = window;
            const width = innerWidth * 0.5;
            const height = width * 0.66;
            window.open(href, "newwindow", `width=${width}, height=${height}`);

            return false;
        }
    }

    const linkComponents = links.map(link => {
        return (
            <Fragment key={link.key}>
                <li>
                    <a href={link.url} target={link.email ? "_self" : "_blank"} rel="noopener noreferrer" data-target={link.newWindow ? "window" : "_blank"} onClick={openLink}>{link.name}</a>
                </li>
                <Line breakTablet={breakTablet} horizontal={horizontal}>
                    <span></span>
                </Line>
            </Fragment>
        );
    });

    return (
        <ShareWrapper onMouseOver={openShareLinks} onMouseOut={closeShareLinks} hideTitleMobile={hideTitleMobile} breakTablet={breakTablet} horizontal={horizontal} small={small} className={className}>
            <p>{title}</p>
            <ShareLinks open={shareOpen} breakTablet={breakTablet} horizontal={horizontal} reverse={reverse} width={width} numLinks={links.length} small={small}>
                <ul>
                    {linkComponents}
                </ul>
            </ShareLinks>
        </ShareWrapper>
    );
};

export default HoverDropdown;
