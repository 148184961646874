import React from "react";
import { isBrowser } from "@emotion/core/src/utils";
import NewsArticle from "../templates/NewsArticle";

const unpublishedNewsArticle = () => {
    if(!isBrowser) {
        return null;
    }
    const previewData = window.__PRISMIC_PREVIEW_DATA__

    return <NewsArticle data={previewData} />
}

export default unpublishedNewsArticle;